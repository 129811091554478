<template>
<div>
        <div class="header-page">
            <div class="header-font">
                Group Course
            </div>
            <div>
                <v-btn color="primary create-btn" @click="createGroup">Create</v-btn>
            </div>
        </div>
        <v-data-table
            :headers="headers"
            :items="groupList"
            class="elevation-1"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <div class="text-truncate">
                <v-icon
                    small
                    class="mr-2"
                    @click="editGroup(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteModal(item)"
                >
                    mdi-delete
                </v-icon>
            </div>
            </template>
        </v-data-table>
        <group-course-form
            ref="groupCourseForm"
            :groupId="groupId"
            :mode="mode"
            @close-modal="resetValueForm"
            @create-success="getGroupList"
            @edit-success="getGroupList"
        ></group-course-form>
        <delete-modal
            ref="deleteModal"
            @delete-success="deleteSuccess"
            :deleteForm="deleteForm"
        ></delete-modal>
    </div>
</template>

<script>
import GroupCourseForm from '../components/GroupCourseForm'
import DeleteModal from '../components/DeleteModal'
export default {
    name: 'GroupCourse',
    components: {
        DeleteModal,
        GroupCourseForm
    },

    data () {
        return {
            groupList: [],
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'courses', value: 'courseName' },
                { text: '', value: 'actions' }
            ],
            groupId: null,
            deleteForm: {
                header: 'Delete group',
                title: 'You want to delete group ',
                url: ''
            },
            mode: ''
        }
    },

    methods: {
        async getGroupList () {
            const res = await this.axios.get('group-courses')
            if (res && res.data && res.data.status === 'ok') {
                this.groupList = res.data.data
            }
        },

        createGroup () {
            this.groupId = null
            this.mode = 'create'
            this.openModal()
        },

        editGroup (item) {
            this.groupId = item.id
            this.mode = 'edit'
            this.openModal()
        },

        openModal () {
            this.$refs.groupCourseForm.dialog = true
        },

        deleteModal (item) {
            this.deleteForm.title = `You want to delete group ${item.name} ?`
            this.deleteForm.url = `group-courses/${item.id}`
            this.$refs.deleteModal.dialog = true
        },

        async deleteSuccess () {
            this.$notify({
                title: 'Congratulations',
                text: 'Delete group success',
                type: 'success'
            })
            await this.getGroupList()
        },

        resetValueForm () {
            this.mode = ''
            this.groupId = null
        }
    },

    async mounted () {
        await this.getGroupList()
    }
}
</script>

<style lang="scss" scoped>

</style>
