<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card class="card-padding">
                <div class="card-form">
                    <div class="header-form">
                        <div class="text-header">{{headerForm}}</div>
                        <div class="pointer" @click="closeModal">X</div>
                    </div>
                    <v-form
                        ref="form"
                        v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-text-field
                                        v-model="form.name"
                                        :rules="inputRules"
                                        label="Name"
                                        :error-messages="errors.name"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-select
                                        v-model="form.courses"
                                        :items="courseForSelect"
                                        :rules="selectRules"
                                        label="Course"
                                        item-text="key"
                                        item-value="value"
                                        :error-messages="errors.courses"
                                        multiple
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                        <div class="btn-container">
                            <v-btn class="mr-4" @click="submit" color="primary">
                                Submit
                            </v-btn>
                            <v-btn @click="closeModal" color="primary" outlined>
                                Cancel
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'GroupCourseForm',
    props: {
        mode: {
            type: String,
            default: null
        },
        groupId: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            dialog: false,
            valid: false,
            inputRules: [
                v => !!v || 'This field is required'
            ],
            selectRules: [
                (v) => v.length > 0 || 'This field is required'
            ],
            form: {
                name: '',
                courses: []
            },
            headerForm: 'Create group',
            errors: [],
            courseForSelect: []
        }
    },

    methods: {
        notifySuccess (text) {
            this.$notify({
                title: 'Congratulations',
                text: text,
                type: 'success'
            })
        },

        async createGroup () {
            const res = await this.axios.post('group-courses', this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Create course success')
                this.$emit('create-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        async editGroup () {
            const res = await this.axios.put(`group-courses/${this.groupId}`, this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Edit course success')
                this.$emit('edit-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        async submit () {
            if (!this.$refs.form.validate()) {
                return
            }
            if (this.mode === 'create') {
                await this.createGroup()
            } else if (this.mode === 'edit') {
                await this.editGroup()
            }
        },

        closeModal () {
            this.dialog = false
            this.resetForm()
            this.$emit('close-modal')
        },

        resetForm () {
            this.$refs.form.reset()
        },

        async getGroup () {
            const res = await this.axios.get(`group-courses/${this.groupId}`)
            if (res && res.data && res.data.status === 'ok') {
                this.form = res.data.data
            }
        },

        async getCourseList () {
            const res = await this.axios.get('/courses-select')
            if (res && res.data && res.data.status === 'ok') {
                this.courseForSelect = res.data.data
            }
        }
    },

    async mounted () {
        await this.getCourseList()
    },

    watch: {
        async mode (newVal, oldVal) {
            if (newVal === 'create') {
                this.headerForm = 'Create group'
            } else if (newVal === 'edit') {
                this.headerForm = 'Edit group'
                this.getGroup()
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
